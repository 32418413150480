<template>
  <div class="main-home">
    <aside class="aside">
      <h2 class="logo">
        <img width="32" height="32" src="@/assets/logo-pic-blue.png" title="beta gis" alt="">
        <img style="margin-left: 10px" v-show="!collapsed" src="@/assets/logo-word-blue.png" alt="">
      </h2>
      <a-menu
        :style="{ width: collapsed ? 'auto' : '243px' }"
        :default-selected-keys="['home']"
        :default-open-keys="['data']"
        mode="inline"
        :theme="theme"
        :selected-keys="[current]"
        :inline-collapsed="collapsed"
        @click="handleClick"
      >
        <template v-for="item in routes" >
          <!-- 一级路由 -->
          <a-menu-item v-if="!item.children" :key="item.path">
            <a-icon :type="item.icon" />
            <span>{{ item.meta.title }}</span>
            <router-link :to="{name: item.name}"> </router-link>
          </a-menu-item>
          <!-- 二级路由 -->
          <a-sub-menu v-else :key="item.path">
            <span slot="title"><a-icon :type="item.icon" /><span>{{item.meta.title}}</span></span>
            <a-menu-item v-for="subitem in item.children" :key="subitem.path">
              {{ subitem.meta.title }}
              <router-link :to="{name: subitem.name}"></router-link>
            </a-menu-item>
          </a-sub-menu>
        </template>
      </a-menu>
    </aside>
    <div class="content">
      <nav>
        <div class="nav">
          <span class="collapsed-btn" type="text" @click="collapsed=!collapsed">
            <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
          </span>
          <keep-alive>
            <a-breadcrumb :routes="breadcrumbRoutes"  separator="/">
              <template slot="itemRender" slot-scope="{ route, routes }"  >
                <span v-if="routes.length === 1" style="font-size: 16px">
                  {{ route.meta.title }}
                </span>
                <router-link v-else :to="`${route.path}`" style="color: #282828; font-size: 16px">
                  {{ route.meta.title }} 
                </router-link>
              </template>
            </a-breadcrumb>
          </keep-alive>
        </div>
        <div class="user-avatar">
          <a-dropdown :trigger="['click']">
            <span style="cursor: pointer; display: inline-block; height: 40px; line-height: 40px">
              <a-avatar :size="30" icon="user" /> 
              <span style="margin: 0 10px" >{{username}}</span>
              <a-icon type="down" />
            </span>
            <a-menu slot="overlay">
              <a-menu-item key="0" disabled>
                修改密码
              </a-menu-item>
              <a-menu-item key="1" @click="logout">
                退出登录
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </nav>
      <div class="sub-nav" v-if="tabVisible">
        <common-tab></common-tab>
      </div>
      <div class="container">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import routes from '@/router/routes'
import CommonTab from '@/views/common/CommonTab'
export default {
  name: 'Home',
  data() {
    return {
      current: 'home',
      theme: 'dark',

      routes: [],
      breadcrumbRoutes: [],
      collapsed: false,

      tabVisible: false,

    }
  },
  computed: {
    username() {
      return window.sessionStorage.getItem('username');
    }
  },
  watch:{
		// 监听路由变化 -- 修改面包屑和菜单选中状态
		$route: {
      handler(routes) {
        this.tabVisible = routes.path.includes('home') ? false : true;
        this.breadcrumbRoutes = routes.matched.filter(items => items.meta.title);
        let pathArr = routes.path.split('/');
        this.current = pathArr[pathArr.length-1];
      },
      deep: true
		}
  },
  components: {
    CommonTab
  },
  methods: {
    handleClick(e) {
      this.current = e.key;
      // console.log('click ', e.key);
    },
    logout() {
      window.sessionStorage.clear();
      this.$router.push({ path: '/login' })
    }
    
  },
  mounted() {
    this.routes = routes[0].children;
    console.log(this.routes);
    // 面包屑只显示当前内容的子元素，不然无法正常显示
    this.breadcrumbRoutes = this.$route.matched.filter(item => item.meta.title);

    let paths = this.$route.path.split('/');
    this.current = paths[paths.length - 1];
  }
}
</script>
<style lang="less" scoped>
.main-home {
  height: 100%;
  display: flex;
  .aside {
    height: 100%;
    background: #001529;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      margin: 0;
    }
  }
  .content {
    width: 100%;
    position: relative;
    nav { 
      height: 68px;
      width: 100%;
      padding: 0 30px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav {
        display: flex;
        align-items: center;
        .collapsed-btn {
          color: #2D3440;
          font-size: 20px;
          cursor: pointer;
          margin-right: 13px;
        }
        .collapsed-btn:hover {
          color: #2093F7
        }
        .ant-breadcrumb {
          height: 76px;
          line-height: 76px;
        }
      }
    }
    .sub-nav {
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      background: #fff;
      margin-top: 1px;
    }
    .container {
      padding: 25px;
      box-sizing: border-box;
    }
  }
}
</style>