// http://124.71.158.158:8080/atlas/front/article/page

import baseUrl from './baseurl';
import axios from 'axios';
import router from '../router'
import {
  message
} from 'ant-design-vue'

// 带拦截token的请求方式
let http = axios.create({
  baseURL: baseUrl,
  timeout: 60000
});
http.__proto__ = axios;

//http request 拦截器
http.interceptors.request.use((config) => {
    // 添加请求头
    if (window.sessionStorage.getItem("token")) {
      config.headers['X-Auth-Token'] = window.sessionStorage.getItem("token");
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);


/*  错误码拦截 */
http.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        if(!window.sessionStorage.getItem('token')) {
          return ;
        }
        window.sessionStorage.clear();
        router.replace({
          path: '/login',
          query: {
            redirect: router.currentRoute.fullPath
          }
        });
        message.error('登录过期，请重新登录');
        break;
        case 404: 
        case 500:
          // oh my god, magic url, it's just because in GWCSeedForm I don't want see error dialog
          if(error.response.config.url.indexOf("/gwc/rest/layers")==-1){
            message.error(error.response.statusText)
          }
          // router.replace({
          //     path: '/login',
          //     query: { redirect: router.currentRoute.fullPath}
          // });
    }
  }
  return Promise.reject(error.response) // 返回接口返回的错误信息
});
export default http;