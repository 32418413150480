<template>
    <div class="tabs">
        <a-tag 
            style="padding: 3px 13px 3px 18px; border-raduis: 2px;" 
            :color="nowPath==tag.path ? '#2093F7': '' "
            v-for="tag in tagList"
            :key="tag.path"
            @close="closeTag(tag)"
            @click="selecteTag(tag)"
            :closable="tag.path=='/home'?false:true"
        >
            {{ tag.meta.title }}
        </a-tag>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tagList: [],
            nowPath: '',
        }
    },
    watch: {
        $route: {
            handler(routes) {
                let index = this.tagList.findIndex( item => item.path == routes.path );
                if(index == -1) {
                    this.tagList.push(routes);
                    this.nowPath = routes.path;
                } else {
                    this.nowPath = this.tagList[index].path;
                }
            },
            deep: true
		}
    },
    methods: {
        closeTag(value) {
            let index = this.tagList.findIndex( item => item.path == value.path);
            this.tagList.splice(index, 1);
            // 删除的是当前标签，页面跳转到上一个标签
            if(this.nowPath == value.path) {
                this.$router.push({path: this.tagList[index-1].path});
            }
        },
        selecteTag(value) {
            if(this.nowPath != value.path) {
                this.nowPath = value.path;
                this.$router.push({ path: this.nowPath })
            }
        }
    },
    mounted() {
        // 默认应该是有首页的路由显示的
        this.tagList.push({
            fullPath: "/home",
            hash: "",
            meta: {
                requireAuth: true,
                title: "首页"
            },
            name: "Home",
            params: {},
            path: "/home",
            query: {}
        });
        if(this.$route.path != '/home') {
            this.tagList.push(this.$route);
            this.nowPath = this.$route.path;
        } else {
            this.nowPath = this.tagList[0].path;
        }
        
    }
}
</script>
