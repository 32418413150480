import Main from '../views/Main.vue'

export default [{
    path: '/',
    name: 'Main',
    component: Main,
    redirect: '/home',
    children: [{
        path: 'home',
        name: 'Home',
        icon: 'home',
        meta: {
          title: '首页',
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
        },
        component: () => import( /* webpackChunkName: "home" */ '../views/pages/home/Home.vue')
      },

      {
        path: 'data',
        name: 'Data',
        icon: 'appstore',
        redirect: 'data/layers',
        meta: {
          title: '数据',
          requireAuth: true, 
        },
        component: () => import( /* webpackChunkName: "data" */ '../views/pages/data/Data.vue'),
        children: [{
            path: 'layers',
            name: 'Layers',
            meta: {
              title: '图层',
              requireAuth: true, 
            },
            component: () => import('../views/pages/data/layers/Layers.vue')
          },
          {
            path: 'storage',
            name: 'Storage',
            meta: {
              title: '数据存储',
              requireAuth: true, 
            },
            component: () => import('../views/pages/data/storage/Storage.vue')
          },
          {
            path: 'workspace',
            name: 'Workspace',
            meta: {
              title: '工作空间',
              requireAuth: true, 
            },
            component: () => import('../views/pages/data/workspace/Workspace.vue')
          },
          {
            path: 'style',
            name: 'Style',
            meta: {
              title: '样式',
              requireAuth: true, 
            },
            component: () => import('../views/pages/data/style/Style.vue')
          },
          {
            path: 'cutaway',
            name: 'Cutaway',
            meta: {
              title: '切图方案',
              requireAuth: true,
            },
            component: () => import('../views/pages/data/cutaway/Cutaway.vue')
          },
        ]
      },
      {
        path: 'service',
        name: 'Service',
        icon: 'mail',
        redirect: 'service/serviceAvailable',
        meta: {
          title: '服务',
          requireAuth: true,
        },
        component: () => import('../views/pages/service/Service.vue'),
        children: [{
            path: 'serviceAvailable',
            name: 'ServiceAvailable',
            meta: {
              title: '服务能力',
              requireAuth: true,
            },
            component: () => import('../views/pages/service/serviceAvailable/ServiceAvailable.vue')
          },
          {
            path: 'wms',
            name: 'WMS',
            meta: {
              title: 'WMS',
              requireAuth: true,
            },
            component: () => import('../views/pages/service/wms/WMS.vue')
          },
          {
            path: 'wmts',
            name: 'WMTS',
            meta: {
              title: 'WMTS',
              requireAuth: true,
            },
            component: () => import('../views/pages/service/wmts/WMTS.vue')
          },
          {
            path: 'wfs',
            name: 'WFS',
            meta: {
              title: 'WFS',
              requireAuth: true,
            },
            component: () => import('../views/pages/service/wfs/WFS.vue')
          },
          {
            path: 'wcs',
            name: 'WCS',
            meta: {
              title: 'WCS',
              requireAuth: true,
            },
            component: () => import('../views/pages/service/wcs/WCS.vue')
          },
          {
            path: 'wps',
            name: 'WPS',
            meta: {
              title: 'WPS',
              requireAuth: true,
            },
            component: () => import('../views/pages/service/wps/WPS.vue')
          },
        ]
      },
      {
        path: 'safe',
        name: 'Safe',
        icon: 'safety',
        redirect: 'safe/authentication',
        meta: {
          title: '安全',
          requireAuth: true,
        },
        component: () => import('../views/pages/service/Service.vue'),
        children: [
          {
            path: 'users',
            name: 'Users',
            meta: {
              title: '用户管理',
              requireAuth: true,
            },
            component: () => import('../views/pages/safe/users/Users.vue')
          },
          {
            path: 'authentication',
            name: 'Authentication',
            meta: {
              title: '认证配置',
              requireAuth: true,
            },
            component: () => import('../views/pages/safe/authentication/Authentication.vue')
          },
        ],
      },
      {
        path: 'system',
        name: 'System',
        icon: 'mail',
        meta: {
          title: '系统',
        },
        component: () => import( /* webpackChunkName: "about" */ '../views/pages/system/System.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/pages/login/Login.vue')
  }
]