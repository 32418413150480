import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 路由拦截
router.beforeEach((to, from, next) => {
  let token = window.sessionStorage.getItem("token");
  if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
    if (token) {      //判断当前的token是否存在
      next();
    }
    else {
      next({
        path: '/login',
        query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  }
  else {
    next();
  }
})

export default router
